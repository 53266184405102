<template>
  <IconButton
    :title="props.title || 'Begin navigation'"
    :aria-label="props.title || 'Begin navigation'"
    :href="props.href"
    :imgSrc="imgSrc"/>
</template>

<script setup lang="ts">
import IconButton from './IconButton.vue';
import imgSrc from "../../assets/navigate-icon-48x48.png";
const props = defineProps<{
  href: string,
  title?: string
}>();
</script>
